import React from "react";
import Heading1 from '../components/typography/Heading1';
import Paragraph from '../components/typography/Paragraph';
import { useLocation, useNavigate } from "react-router-dom";
import OptInForm from '../forms/OptInForm';
import { clearCurrentRegistrant } from '../store/registrant/registrantSlice'
import { useDispatch, useSelector } from "react-redux";
import Wrapper from "../components/layout/Wrapper";
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";

const content = {
  "en-US": {
      not_eligible_heading: "We won't be able to register you today. Sign up to receive information on other ways to help on election day.",
      default_heading: "One of our staff members can follow up with you.",
      not_eligible_paragraph: "Enter your contact information below.",
      default_paragraph: "Please share your contact information with us so that we can follow up with you on your voter status.",
  },
  "es-ES": {
      not_eligible_heading: "No podremos registrarlo en el día de hoy. Inscríbase para recibir información sobre otras formas de ayudar el día de las elecciones.",
      default_heading: "Uno de los miembros de nuestro personal puede ponerse en contacto con usted.",
      not_eligible_paragraph: "Ingrese su información de contacto a continuación.",
      default_paragraph: "Comparta su información de contacto con nosotros para que podamos hacer el seguimiento con usted o visite bringit.wi.gov para obtener más información.",
  }
};

const OptInPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  const handleOptIn = () => {
    dispatch(clearCurrentRegistrant());
    navigate('/thank-you', { state: { type: location?.state?.registrationType } });
  };

  return (
    <Wrapper>
      <ContentLeft>
        <>
          {location?.state?.registrationType === 'not_eligible' ? (
            <>
              <Heading1 mb={4}>{content[currentLanguage].not_eligible_heading}</Heading1>
              <Paragraph mb={4}>{content[currentLanguage].not_eligible_paragraph}</Paragraph>
            </>
          ) : (
            <>
              <Heading1 mb={4}>{content[currentLanguage].default_heading}</Heading1>
              <Paragraph mb={4}>{content[currentLanguage].default_paragraph}</Paragraph>
            </>
          )}
        </>
      </ContentLeft>
      <ContentRight>
        <OptInForm onSubmit={handleOptIn} registrationType={location?.state?.registrationType} />
      </ContentRight>
    </Wrapper>
  );
}

export default OptInPage;