import * as React from "react";
import Heading1 from "../components/typography/Heading1";
import Heading2 from "../components/typography/Heading2";
import Webcam from "react-webcam";
import FormWrapper from "../components/layout/FormWrapper";
import PrimaryButton from "../components/buttons/PrimaryButton";
import TextButton from "../components/buttons/TextButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  updateRegistrant,
  clearCurrentRegistrant,
} from "../store/registrant/registrantSlice";
import {
  generateRegistrationID,
  createFormPayload,
  createImagePayload,
  sendSurveyData,
  getPublicKey,
} from "../services/api/voter";
import Bugsnag from "@bugsnag/js";
import ContentColumn from "../components/layout/ContentColumn";
import { buttons } from '../content';

export const content = {
  "en-US": {
    heading: "Place your document within the square below and capture using the button.",
    heading_confirm: "Please make sure the photo is clear and readable.",
  },
  "es-ES": {
    heading: "Coloque su documento dentro del cuadro debajo y presione el botón para tomar una foto.",
    heading_confirm: "Asegúrese de que la foto sea clara y legible.",
  }
};

const ProofOfResidencePage = () => {
  const registrant = useSelector((state) => state.registrant);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  const [porScreenshot, setPORScreenshot] = React.useState(null);
  const webcamRef = React.useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPORScreenshot(imageSrc);
  }, [webcamRef]);

  const nextStep = async () => {
    dispatch(
      updateRegistrant({
        has_proof_of_residence: true,
      })
    );

    try {
      const responseID = generateRegistrationID();
      const pubKey = await getPublicKey();

      const encryptedResult = createFormPayload(
        registrant,
        "registration_offline",
        "en",
        pubKey.public_key
      );
      const encryptedSignature = createImagePayload(
        registrant.signature_data,
        'sig',
        pubKey.public_key
      );
      const encryptedPOR = createImagePayload(
        porScreenshot.replace("data:image/jpeg;base64,", ""),
        'por',
        pubKey.public_key
      );

      let formRequest = sendSurveyData(
        responseID,
        null,
        "form",
        encryptedResult.key,
        encryptedResult.payload
      )
        .then(() => {
          navigate("/thank-you", { state: { type: 'offline_registration' } });
        })
        .catch(async (error) => {
          alert(
            "An error occurred while processing the form.  Please try again."
          );
          Bugsnag.notify(error);
        });

      let signatureRequest = sendSurveyData(
        responseID,
        null,
        "sig",
        encryptedSignature.key,
        encryptedSignature.payload
      )
        .then(() => {
          navigate("/thank-you", { state: { type: 'offline_registration' } });
        })
        .catch(async (error) => {
          alert(
            "An error occurred while processing the signature image.  Please try again."
          );
          Bugsnag.notify(error);
        });

      let porRequest = sendSurveyData(
        responseID,
        null,
        "por",
        encryptedPOR.key,
        encryptedPOR.payload
      )
        .then(() => {
          navigate("/thank-you", { state: { type: 'offline_registration' } });
        })
        .catch(async (error) => {
          alert(
            "An error occurred while processing the POR image.  Please try again."
          );
          Bugsnag.notify(error);
        });

      await Promise.all([formRequest, signatureRequest, porRequest]);

      dispatch(clearCurrentRegistrant());
      navigate("/thank-you", { state: { type: 'offline_registration' } });
    } catch (e) {
      Bugsnag.notify(e);
      alert("An error occurred while submitting the form.  Please try again.");
    }
  };

  return (
    <ContentColumn>
      <ContentColumn.Header>
        <Heading1>{content[currentLanguage].heading}</Heading1>
      </ContentColumn.Header>
      <ContentColumn.Content>
        <FormWrapper>
          <div className="p-8">
            {!porScreenshot ? (
              <Webcam
                audio={false}
                screenshotFormat="image/jpeg"
                height={420}
                width={"100%"}
                ref={webcamRef}
              />
            ) : (
              <>
                <div className="flex flex-col items-center justify-center">
                  <Heading2 textTransform={"uppercase"} color={"red"} mb="4">
                    {content[currentLanguage].heading_confirm}
                  </Heading2>
                </div>
                <img
                  className="w-full h-[420px] object-contain"
                  src={porScreenshot}
                  alt=""
                />
              </>
            )}
          </div>
        </FormWrapper>
        {!porScreenshot ? (
          <div className="flex flex-col items-center justify-center my-4">
            <PrimaryButton onClick={capture} title={buttons[currentLanguage].capture_por} />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center my-4">
            <PrimaryButton onClick={nextStep} title={buttons[currentLanguage].submit_registration} />
            <TextButton
              onClick={() => setPORScreenshot(null)}
              title={buttons[currentLanguage].retake_image}
            />
          </div>
        )}
      </ContentColumn.Content>
    </ContentColumn>
  );
};

export default ProofOfResidencePage;
