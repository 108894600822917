import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { store } from "./store";
import { useEffect } from "react";
import { Provider, useSelector } from "react-redux";
import CheckRegistrationPage from "./pages/CheckRegistrationPage";
import DownloadPage from "./pages/DownloadPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import EligibilityPage from "./pages/EligibilityPage";
import RegisteredPage from "./pages/RegisteredPage";
import IDCheckPage from "./pages/IDCheckPage";
import OptInPage from "./pages/OptInPage";
import RegisterOnlinePage from "./pages/RegisterOnlinePage";
import ThankYouPage from "./pages/ThankYouPage";
import IDInformationPage from "./pages/IDInformationPage";
import MailingAddressPage from "./pages/MailingAddressPage";
import AdditionalQuestionsPage from "./pages/AdditionalQuestionsPage";
import ReviewPage from "./pages/ReviewPage";
import SignaturePage from "./pages/SignaturePage";
import ProofOfResidencePage from "./pages/ProofOfResidencePage";
import ReturnUserPage from "./pages/ReturnUserPage";
import DateOfBirthConsentPage from "./pages/DateOfBirthConsentPage";
import UpdateNamePage from "./pages/UpdateNamePage";
import PORIntroPage from "./pages/PORIntroPage";
import NameNotUpdatedPage from "./pages/NameNotUpdatedPage";
import UpdateNameFormPage from "./pages/UpdateNameFormPage";
import UpdateAddressPage from "./pages/UpdateAddressPage";
import UpdateAddressFormPage from "./pages/UpdateAddressFormPage";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import ProgressBar from "./components/layout/ProgressBar";
import { updatePartner } from "./store/partner/partnerSlice";
import { getPartnerInfo } from "./services/api/partner";

import "./App.scss";

const GuardedRoute = ({ children }) => {
  const checkedRegistration = useSelector((state) => state.registrant.registered);
  const partnerInfo = useSelector((state) => state.partner);

  if (!partnerInfo.can_register) {
    return <Navigate to="/" replace />;
  } else if (process.env.REACT_APP_CANVASSER_ID == '' || process.env.REACT_APP_CANVASSER_ID == null || process.env.REACT_APP_CANVASSER_ID == '0') {
    return <Navigate to="/download" replace />;
  } else if (checkedRegistration == null) {
    return <Navigate to="/" replace />;
  }
  return children;
};

const CheckRoute = ({ children }) => {
  if (process.env.REACT_APP_CANVASSER_ID == '' || process.env.REACT_APP_CANVASSER_ID == null || process.env.REACT_APP_CANVASSER_ID == '0') {
    return <Navigate to="/download" replace />;
  }

  return children;
};

function App() {
  useEffect(() => {
    getPartnerInfo().then((result) => {
      // console.log(result);
      store.dispatch(updatePartner({
        name: result.partner.name,
        logo_image: result.partner.ui.logo_image,
        logo_image_dark: result.partner.ui.logo_image_dark,
        can_register: result.partner.settings.can_register,
        can_register_online: result.partner.settings.can_register_online,
        can_register_offline: result.partner.settings.can_register_offline,
        offline_disabled: result.partner.settings.offline_disabled,
        no_register_online_message: result.partner.settings.no_register_online_message,
        no_register_offline_message: result.partner.settings.no_register_offline_message,
        no_register_message: result.partner.settings.no_register_message,
        no_register_online_message_es: result.partner.settings.no_register_online_message_es,
        no_register_offline_message_es: result.partner.settings.no_register_offline_message_es,
        no_register_message_es: result.partner.settings.no_register_message_es,
      }));
    });
  }, [])

  return (
    <Provider store={store}>
      <div>
        <Header />
        <main>
          <ProgressBar />
          <div className="content__container">
            <div className="content__scroll flex flex-col">
              <BrowserRouter>
                <Routes>
                  <Route 
                    path="/" 
                    element={
                      <CheckRoute>
                        <CheckRegistrationPage />
                      </CheckRoute>
                    } 
                  />
                  <Route path="/download" element={<DownloadPage />} />
                  <Route 
                    path="/return-user" 
                    element={
                      <CheckRoute>
                        <ReturnUserPage />
                      </CheckRoute>
                    } 
                  />
                  <Route
                    path="/eligibility"
                    element={
                      <GuardedRoute>
                        <EligibilityPage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/dob-consent"
                    element={
                      <GuardedRoute>
                        <DateOfBirthConsentPage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/privacy-policy"
                    element={<PrivacyPage />}
                  />
                  <Route
                    path="/terms-of-use"
                    element={<TermsPage />}
                  />
                  <Route
                    path="/id-check"
                    element={
                      <GuardedRoute>
                        <IDCheckPage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/registered"
                    element={
                      <GuardedRoute>
                        <RegisteredPage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/thank-you"
                    element={
                      <ThankYouPage />
                    }
                  />
                  <Route
                    path="/register-online"
                    element={
                      <GuardedRoute>
                        <RegisterOnlinePage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/id-information"
                    element={
                      <GuardedRoute>
                        <IDInformationPage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/mailing-address"
                    element={
                      <GuardedRoute>
                        <MailingAddressPage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/additional-questions"
                    element={
                      <GuardedRoute>
                        <AdditionalQuestionsPage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/review"
                    element={
                      <GuardedRoute>
                        <ReviewPage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/signature"
                    element={
                      <GuardedRoute>
                        <SignaturePage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/proof-of-residence-intro"
                    element={
                      <GuardedRoute>
                        <PORIntroPage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/proof-of-residence"
                    element={
                      <GuardedRoute>
                        <ProofOfResidencePage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/registered"
                    element={
                      <GuardedRoute>
                        <RegisteredPage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/opt-in"
                    element={
                      <CheckRoute>
                        <OptInPage />
                      </CheckRoute>
                    }
                  />
                  <Route
                    path="/update-name"
                    element={
                      <GuardedRoute>
                        <UpdateNamePage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/name-not-updated"
                    element={
                      <GuardedRoute>
                        <NameNotUpdatedPage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/update-name-form"
                    element={
                      <GuardedRoute>
                        <UpdateNameFormPage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/update-address"
                    element={
                      <GuardedRoute>
                        <UpdateAddressPage />
                      </GuardedRoute>
                    }
                  />
                  <Route
                    path="/update-address-form"
                    element={
                      <GuardedRoute>
                        <UpdateAddressFormPage />
                      </GuardedRoute>
                    }
                  />
                </Routes>
              </BrowserRouter>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </Provider>
  );
}

export default App;
