import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import InputText from '../components/inputs/InputText';
import InputDropdown from '../components/inputs/InputDropdown';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { isEmpty, isValidZip } from '../helpers';
import { formLabels, formErrors, buttons } from '../content';
import Spinner from '../components/icons/Spinner';

const NewAddressForm = ({ onSubmit, ...props }) => {
    const registrant = useSelector((state) => state.registrant);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const [formData, setData] = React.useState({});
    const [errors, setErrors] = React.useState({});
    const [submitting, setSubmitting] = React.useState(false);

    const {
        new_address_1,
        new_address_2_type,
        new_address_2_number,
        new_city,
        new_state,
        new_zipcode,
    } = registrant;

    React.useEffect(() => {
        setData({
            new_address_1,
            new_address_2_type,
            new_address_2_number,
            new_city,
            new_state,
            new_zipcode,
        });
    }, [registrant]);

    const isValid = () => {
        let checkErrors = {};

        if (isEmpty(formData.new_address_1)) {
            checkErrors = {
                ...checkErrors,
                new_address_1: formErrors[currentLanguage].address_required,
            };
        } else if (formData.new_address_1.length > 255) {
            checkErrors = {
                ...checkErrors,
                new_address_1: formErrors[currentLanguage].address_required,
            };
        }

        if (isEmpty(formData.new_address_2_number) && !isEmpty(formData.new_address_2_type)) {
            checkErrors = {
                ...checkErrors,
                new_address_2_number: formErrors[currentLanguage].unit_number_required,
            };
        } else if (!isEmpty(formData.new_address_2_number) && formData.new_address_2_number.length > 15) {
            checkErrors = {
                ...checkErrors,
                new_address_2_number: formErrors[currentLanguage].unit_number_required,
            };
        }

        if (isEmpty(formData.new_city)) {
            checkErrors = {
                ...checkErrors,
                new_city: formErrors[currentLanguage].city_required,
            };
        } else if (formData.new_city.length > 50) {
            checkErrors = {
                ...checkErrors,
                new_city: formErrors[currentLanguage].city_required,
            };
        }

        if (isEmpty(formData.new_state)) {
            checkErrors = {
                ...checkErrors,
                new_state: formErrors[currentLanguage].state_required,
            };
        } else if (formData.new_state !== 'WI') {
            checkErrors = {
                ...checkErrors,
                new_state: formErrors[currentLanguage].state_is_wi,
            };
        }

        if (isEmpty(formData.new_zipcode)) {
            checkErrors = {
                ...checkErrors,
                new_zipcode: formErrors[currentLanguage].zipcode_required,
            };
        } else if (!isValidZip(formData.new_zipcode)) {
            checkErrors = {
                ...checkErrors,
                new_zipcode: formErrors[currentLanguage].zipcode_required,
            };
        } else if (formData.new_zipcode.length > 10) {
            checkErrors = {
                ...checkErrors,
                new_zipcode: formErrors[currentLanguage].zipcode_required,
            };
        }

        setErrors(checkErrors);

        if (!!Object.keys(checkErrors).length) {
            return false;
        } else {
            return true;
        }
    };

    const handleSubmit = async () => {
        if (!isValid()) {
            return;
        }

        try {
            setSubmitting(true);
            
            const {
                new_address_1,
                new_address_2_type,
                new_address_2_number,
                new_city,
                new_state,
                new_zipcode,
            } = formData;

            onSubmit({
                new_address_1,
                new_address_2_type,
                new_address_2_number,
                new_city,
                new_state,
                new_zipcode
            });
        } catch (error) {
            setSubmitting(false);
        }
    };

    return (
        <div className="relative">
            {submitting &&
                <div className="rounded-lg absolute w-full h-full justify-center items-center z-10 overflow-hidden">
                    <div className="rounded-lg bg-[#7395D4] absolute w-full h-full opacity-30"></div>
                    <Spinner />
                </div>
            }
            <div className="p-8">

                <div>
                    <InputText
                        label={formLabels[currentLanguage].new_address_1}
                        required
                        name="new_address_1"
                        errors={errors}
                        placeholder="5555 Oak St."
                        onChange={e => {
                            setData({ ...formData, new_address_1: e.target.value });
                        }}
                        onBlur={() => {
                            const newErrors = delete errors.new_address_1;
                            setErrors({
                                ...newErrors
                            })
                        }}
                        // onSubmitEditing={handleSubmit}
                        value={formData.new_address_1}
                    />
                </div>

                <div className="flex flex-row">
                    <div className="flex-1">
                        <InputDropdown
                            name="new_address_2_type"
                            label={formLabels[currentLanguage].apt_type}
                            errors={errors}
                            placeholder={formLabels[currentLanguage].select_option}
                            options={[
                                // { value: "757580000", label: "APT"},
                                // { value: "757580001", label: "UNIT"},
                                // { value: "757580002", label: "RM"},
                                // { value: "757580003", label: "SUITE"},
                                // { value: "757580004", label: "BSMT"},
                                // { value: "757580005", label: "BLDG"},
                                // { value: "757580006", label: "DEPT"},
                                // { value: "757580007", label: "FL"},
                                // { value: "757580008", label: "FRNT"},
                                // { value: "757580009", label: "HNGR"},
                                // { value: "757580010", label: "KEY"},
                                // { value: "757580011", label: "LBBY"},
                                // { value: "757580012", label: "LOT"},
                                // { value: "757580013", label: "LOWR"},
                                // { value: "757580014", label: "OFC"},
                                // { value: "757580015", label: "PH"},
                                // { value: "757580016", label: "PIER"},
                                // { value: "757580017", label: "SIDE"},
                                // { value: "757580018", label: "REAR"},
                                // { value: "757580019", label: "SLIP"},
                                // { value: "757580020", label: "SPC"},
                                // { value: "757580021", label: "STOP"},
                                // { value: "757580022", label: "UPPR"},
                                // { value: "757580023", label: "TRLR"},
                                // { value: "757580024", label: "BOX"},
                                { value: "APT", label: "APT"},
                                { value: "UNIT", label: "UNIT"},
                                { value: "RM", label: "RM"},
                                { value: "SUITE", label: "SUITE"},
                                { value: "BSMT", label: "BSMT"},
                                { value: "BLDG", label: "BLDG"},
                                { value: "DEPT", label: "DEPT"},
                                { value: "FL", label: "FL"},
                                { value: "FRNT", label: "FRNT"},
                                { value: "HNGR", label: "HNGR"},
                                { value: "KEY", label: "KEY"},
                                { value: "LBBY", label: "LBBY"},
                                { value: "LOT", label: "LOT"},
                                { value: "LOWR", label: "LOWR"},
                                { value: "OFC", label: "OFC"},
                                { value: "PH", label: "PH"},
                                { value: "PIER", label: "PIER"},
                                { value: "SIDE", label: "SIDE"},
                                { value: "REAR", label: "REAR"},
                                { value: "SLIP", label: "SLIP"},
                                { value: "SPC", label: "SPC"},
                                { value: "STOP", label: "STOP"},
                                { value: "UPPR", label: "UPPR"},
                                { value: "TRLR", label: "TRLR"},
                                { value: "BOX", label: "BOX"},
                            ]}
                            onValueChange={value => {
                                const newErrors = delete errors.new_address_2_type;
                                setErrors({
                                    ...newErrors
                                })
                                setData({ ...formData, new_address_2_type: value });
                            }}
                            // onSubmitEditing={handleSubmit}
                        />
                    </div>
                    <div className="flex-1">
                        <InputText
                            label={formLabels[currentLanguage].apt_number}
                            name="new_address_2_number"
                            errors={errors}
                            placeholder="40B"
                            onChange={e => {
                                setData({ ...formData, new_address_2_number: e.target.value });
                            }}
                            onBlur={() => {
                                const newErrors = delete errors.new_address_2_number;
                                setErrors({
                                    ...newErrors
                                })
                            }}
                            // onSubmitEditing={handleSubmit}
                            value={formData.new_address_2_number}
                        />
                    </div>
                </div>

                <div className="flex flex-row">
                    <div className="flex-1">
                        <InputText
                            label={formLabels[currentLanguage].city}
                            name="new_city"
                            errors={errors}
                            placeholder="City"
                            required
                            onChange={e => {
                                setData({ ...formData, new_city: e.target.value });
                            }}
                            onBlur={() => {
                                const newErrors = delete errors.new_city;
                                setErrors({
                                    ...newErrors
                                })
                            }}
                            // onSubmitEditing={handleSubmit}
                            value={formData.new_city}
                        />
                    </div>
                    <div className="flex-1">
                        <InputDropdown
                            name="new_state"
                            label={formLabels[currentLanguage].state}
                            required
                            errors={errors}
                            placeholder={formLabels[currentLanguage].select_option}
                            options={[
                                {
                                    label: "Alabama",
                                    value: "AL"
                                },
                                {
                                    label: "Alaska",
                                    value: "AK"
                                },
                                {
                                    label: "American Samoa",
                                    value: "AS"
                                },
                                {
                                    label: "Arizona",
                                    value: "AZ"
                                },
                                {
                                    label: "Arkansas",
                                    value: "AR"
                                },
                                {
                                    label: "California",
                                    value: "CA"
                                },
                                {
                                    label: "Colorado",
                                    value: "CO"
                                },
                                {
                                    label: "Connecticut",
                                    value: "CT"
                                },
                                {
                                    label: "Delaware",
                                    value: "DE"
                                },
                                {
                                    label: "District Of Columbia",
                                    value: "DC"
                                },
                                {
                                    label: "Federated States Of Micronesia",
                                    value: "FM"
                                },
                                {
                                    label: "Florida",
                                    value: "FL"
                                },
                                {
                                    label: "Georgia",
                                    value: "GA"
                                },
                                {
                                    label: "Guam",
                                    value: "GU"
                                },
                                {
                                    label: "Hawaii",
                                    value: "HI"
                                },
                                {
                                    label: "Idaho",
                                    value: "ID"
                                },
                                {
                                    label: "Illinois",
                                    value: "IL"
                                },
                                {
                                    label: "Indiana",
                                    value: "IN"
                                },
                                {
                                    label: "Iowa",
                                    value: "IA"
                                },
                                {
                                    label: "Kansas",
                                    value: "KS"
                                },
                                {
                                    label: "Kentucky",
                                    value: "KY"
                                },
                                {
                                    label: "Louisiana",
                                    value: "LA"
                                },
                                {
                                    label: "Maine",
                                    value: "ME"
                                },
                                {
                                    label: "Marshall Islands",
                                    value: "MH"
                                },
                                {
                                    label: "Maryland",
                                    value: "MD"
                                },
                                {
                                    label: "Massachusetts",
                                    value: "MA"
                                },
                                {
                                    label: "Michigan",
                                    value: "MI"
                                },
                                {
                                    label: "Minnesota",
                                    value: "MN"
                                },
                                {
                                    label: "Mississippi",
                                    value: "MS"
                                },
                                {
                                    label: "Missouri",
                                    value: "MO"
                                },
                                {
                                    label: "Montana",
                                    value: "MT"
                                },
                                {
                                    label: "Nebraska",
                                    value: "NE"
                                },
                                {
                                    label: "Nevada",
                                    value: "NV"
                                },
                                {
                                    label: "New Hampshire",
                                    value: "NH"
                                },
                                {
                                    label: "New Jersey",
                                    value: "NJ"
                                },
                                {
                                    label: "New Mexico",
                                    value: "NM"
                                },
                                {
                                    label: "New York",
                                    value: "NY"
                                },
                                {
                                    label: "North Carolina",
                                    value: "NC"
                                },
                                {
                                    label: "North Dakota",
                                    value: "ND"
                                },
                                {
                                    label: "Northern Mariana Islands",
                                    value: "MP"
                                },
                                {
                                    label: "Ohio",
                                    value: "OH"
                                },
                                {
                                    label: "Oklahoma",
                                    value: "OK"
                                },
                                {
                                    label: "Oregon",
                                    value: "OR"
                                },
                                {
                                    label: "Palau",
                                    value: "PW"
                                },
                                {
                                    label: "Pennsylvania",
                                    value: "PA"
                                },
                                {
                                    label: "Puerto Rico",
                                    value: "PR"
                                },
                                {
                                    label: "Rhode Island",
                                    value: "RI"
                                },
                                {
                                    label: "South Carolina",
                                    value: "SC"
                                },
                                {
                                    label: "South Dakota",
                                    value: "SD"
                                },
                                {
                                    label: "Tennessee",
                                    value: "TN"
                                },
                                {
                                    label: "Texas",
                                    value: "TX"
                                },
                                {
                                    label: "Utah",
                                    value: "UT"
                                },
                                {
                                    label: "Vermont",
                                    value: "VT"
                                },
                                {
                                    label: "Virgin Islands",
                                    value: "VI"
                                },
                                {
                                    label: "Virginia",
                                    value: "VA"
                                },
                                {
                                    label: "Washington",
                                    value: "WA"
                                },
                                {
                                    label: "West Virginia",
                                    value: "WV"
                                },
                                {
                                    label: "Wisconsin",
                                    value: "WI"
                                },
                                {
                                    label: "Wyoming",
                                    value: "WY"
                                }
                            ]}
                            onValueChange={value => {
                                const newErrors = delete errors.new_state;
                                setErrors({
                                    ...newErrors
                                })
                                setData({ ...formData, new_state: value });
                            }}
                            selectedValue={formData.new_state}
                            // onSubmitEditing={handleSubmit}
                        />
                    </div>
                </div>

                <div className="flex flex-row">
                    <div className="flex-1">
                        <InputText
                            label={formLabels[currentLanguage].zipcode}
                            name="new_zipcode"
                            errors={errors}
                            required
                            placeholder="00000"
                            onChange={e => {
                                setData({ ...formData, new_zipcode: e.target.value });
                            }}
                            onBlur={() => {
                                const newErrors = delete errors.new_zipcode;
                                setErrors({
                                    ...newErrors
                                })
                            }}
                            // onSubmitEditing={handleSubmit}
                            value={formData.new_zipcode}
                            maskType="zipcode"
                        />
                    </div>
                </div>

                <div className="px-1 mt-4">
                    <div className="flex flex-row mt-3 items-center justify-end">
                        <div className="ml-4">
                            <PrimaryButton onClick={handleSubmit} title={buttons[currentLanguage].next_step} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

NewAddressForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default NewAddressForm;
