import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import HeadingExtraLarge from '../components/typography/HeadingExtraLarge';
import Paragraph from '../components/typography/Paragraph';
import { useLocation, useNavigate } from "react-router-dom";
import ThankYouImage from "../components/images/ThankYouImage";
import { changeStep } from "../store/settings/settingsSlice";

export const content = {
  "en-US": {
      onroll_heading: "Thank You!",
      onroll_paragraph: "It looks like you're all set, and ready for election day!",
      not_eligible_heading: "Thank You!",
      not_eligible_paragraph: "It looks like you might not be eligible to vote, but you can still find other ways to help by connecting with us. If you have any questions, you can follow up at myvote.wi.gov",
      not_eligible_no_followup_paragraph: "It looks like you might not be eligible to vote. If you have any questions, you can follow up at myvote.wi.gov.",
      online_heading: "You're Registered!",
      online_paragraph: "It looks like you're all set, and ready for election day!",
      offline_heading: "Thank You!",
      offline_paragraph: "We're currently processing your registration.",
      got_to_go_heading: "Thank You!",
      got_to_go_paragraph: "Look out for a link in your email and return to finish your registration when ready.",
  },
  "es-ES": {
      onroll_heading: "¡Gracias!",
      onroll_paragraph: "¡Parece que está listo para el día de la elección!",
      not_eligible_heading: "¡Gracias!",
      not_eligible_paragraph: "Parece que usted no sería elegible para votar, pero aun así puede encontrar otras formas de ayudar comunicándose con nosotros. Si tiene preguntas, puede consultar en myvote.wi.gov",
      not_eligible_no_followup_paragraph: "Parece que usted no sería elegible para votar. Si tiene preguntas, puede consultar en myvote.wi.gov.",
      online_heading: "¡Está registrado!",
      online_paragraph: "¡Parece que está listo para el día de la elección!",
      offline_heading: "¡Gracias!",
      offline_paragraph: "Actualmente estamos procesando su registro.",
      got_to_go_heading: "¡Gracias!",
      got_to_go_paragraph: "Busque un enlace en su correo electrónico y regrese para terminar su registro cuando esté listo.",
  },
};

const ThankYouPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  React.useEffect(() => {
    dispatch(changeStep([6, 6]));
  }, []);

  const renderThankYouMessage = () => {
    if (location?.state?.type === 'on_roll') {
        return <>
            <HeadingExtraLarge>{content[currentLanguage].onroll_heading}</HeadingExtraLarge>
            <Paragraph mt={4}>{content[currentLanguage].onroll_paragraph}</Paragraph>
        </>;
    } else if(location?.state?.type === 'not_eligible') {
        return <>
            <HeadingExtraLarge>{content[currentLanguage].not_eligible_heading}</HeadingExtraLarge>
            <Paragraph mt={4}>{content[currentLanguage].not_eligible_paragraph}</Paragraph>
        </>;
    } else if (location?.state?.type === 'not_eligible_no_followup') {
        return <>
            <HeadingExtraLarge>{content[currentLanguage].not_eligible_heading}</HeadingExtraLarge>
            <Paragraph mt={4}>{content[currentLanguage].not_eligible_no_followup_paragraph}</Paragraph>
        </>;
    } else if (location?.state?.type === 'online_registration') {
        return <>
            <HeadingExtraLarge>{content[currentLanguage].online_heading}</HeadingExtraLarge>
            <Paragraph mt={4}>{content[currentLanguage].online_paragraph}</Paragraph>
        </>;
    } else if (location?.state?.type === 'offline_registration') {
        return <>
            <HeadingExtraLarge>{content[currentLanguage].offline_heading}</HeadingExtraLarge>
            <Paragraph mt={4}>{content[currentLanguage].offline_paragraph}</Paragraph>
        </>;
    } else if (location?.state?.type === 'got_to_go') {
        return <>
            <HeadingExtraLarge>{content[currentLanguage].got_to_go_heading}</HeadingExtraLarge>
            <Paragraph mt={4}>{content[currentLanguage].got_to_go_paragraph}</Paragraph>
        </>;
    } else {
        return <>
            <HeadingExtraLarge>{content[currentLanguage].offline_heading}</HeadingExtraLarge>
        </>;
    }
  };

  return (
    <div className="blue-background flex-1 flex flex-col">
      <div className="flex-1 w-full h-full flex flex-col lg:flex-row lg:justify-between lg:max-w-[80%] mx-auto py-10 justify-center relative z-10">
        <div className="px-4 sm:px-8 lg:px-0 w-full lg:w-6/12">
          <div className="w-full md:max-w-[80%] lg:max-w-none mx-auto lg:pt-10">
            <ThankYouImage />
          </div>
        </div>
        <div className="px-2 sm:px-6 lg:px-0 w-full lg:w-6/12 py-8 lg:py-0">
          <div className="w-full h-full md:max-w-[80%] lg:max-w-none mx-auto flex flex-col items-center lg:items-start lg:pt-20 lg:pl-20">
            {renderThankYouMessage()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;