import * as React from 'react';
import Paragraph from '../components/typography/Paragraph';
import Heading1 from '../components/typography/Heading1';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxList from '../components/inputs/CheckboxList';
import { updateRegistrant } from '../store/registrant/registrantSlice';
import { useNavigate } from 'react-router-dom';
import Wrapper from "../components/layout/Wrapper";
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";
import { buttons } from '../content';

export const content = {
  "en-US": {
    heading: "Let's confirm you are eligible to vote.",
    content: "Please confirm the following statements.",
    check_is_citizen: "I am a citizen of the United States of America.",
    check_is_valid_age: "I will be 18 years of age or older on or before the first election at which I will offer to vote.",
    check_is_legal: "I am not currently serving a sentence including incarceration, parole, probation, or extended supervision for a felony conviction, and not otherwise disqualified from voting.",
    check_is_address_valid: "I have resided at my current address for at least 28 consecutive days prior to the election and do not currently intend to move.",
},
  "es-ES": {
    heading: "Confirmemos si es elegible para votar.",
    content: "Confirme las siguientes afirmaciones.",
    check_is_citizen: "Soy ciudadano de los Estados Unidos de América.",
    check_is_valid_age: "Tendré 18 años de edad o más el día o antes del día de la primera elección en la cual ofreceré mi voto.",
    check_is_legal: "En este momento no estoy cumpliendo una condena que incluya encarcelamiento, libertad condicional o supervisión extendida por una condena por un delito grave, y no estoy descalificado para votar por otros motivos.",
    check_is_address_valid: "He vivido en mi dirección actual por lo menos 28 días consecutivos antes de la elección y no planeo mudarme actualmente.",
  }
};

function EligibilityPage() {
  const [value, setValue] = React.useState([]);
  const registrant = useSelector((state) => state.registrant);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    console.log('populate registrant')
    populateCheckboxes();
  }, [registrant]);


  React.useEffect(() => {
    console.log('populate onload')
    populateCheckboxes();
  }, []);

  const populateCheckboxes = () => {
    const {
      check_is_citizen,
      check_is_valid_age,
      check_is_legal,
      check_is_address_valid,
    } = registrant;

    console.log('check_is_citizen', check_is_citizen)
    console.log('check_is_valid_age', check_is_valid_age)
    console.log('check_is_legal', check_is_legal)
    console.log('check_is_address_valid', check_is_address_valid)

    const checkboxValues = [
      check_is_citizen ? 'check_is_citizen' : null,
      check_is_valid_age ? 'check_is_valid_age' : null,
      check_is_legal ? 'check_is_legal' : null,
      check_is_address_valid ? 'check_is_address_valid' : null,
    ].filter(i => i);

    console.log('checkboxValues', checkboxValues)
    setValue(checkboxValues);
  };

  const nextQuestion = () => {
    dispatch(updateRegistrant({
      check_is_citizen: value?.includes('check_is_citizen'),
      check_is_valid_age: value?.includes('check_is_valid_age'),
      check_is_legal: value?.includes('check_is_legal'),
      check_is_address_valid: value?.includes('check_is_address_valid'),
    }));

    if (value?.includes('check_is_citizen') &&
      value?.includes('check_is_valid_age') &&
      value?.includes('check_is_legal') &&
      value?.includes('check_is_address_valid')) {
      navigate('/id-check');
    } else {
      navigate('/opt-in', { state: { registrationType: 'not_eligible' } });
    }
  };

  const onChange = (value) => setValue(value);

  return (
    <Wrapper>
      <ContentLeft>
        <Heading1 mb='4'>{content[currentLanguage].heading}</Heading1>
        <Paragraph mb={4}>{content[currentLanguage].content}</Paragraph>
      </ContentLeft>
      <ContentRight>
        <div className="p-4">
          <CheckboxList value={value} onChange={onChange} width="100%" items={[
            { label: content[currentLanguage].check_is_citizen, value: 'check_is_citizen' },
            { label: content[currentLanguage].check_is_valid_age, value: 'check_is_valid_age' },
            { label: content[currentLanguage].check_is_legal, value: 'check_is_legal' },
            { label: content[currentLanguage].check_is_address_valid, value: 'check_is_address_valid' }
          ]} />
          <div className="w-full flex justify-end mt-8">
            <PrimaryButton onClick={nextQuestion} title={buttons[currentLanguage].next_question} />
          </div>
        </div>
      </ContentRight>
    </Wrapper>
  );
}

export default EligibilityPage;
