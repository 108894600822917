
export const formLabels = {
    "en-US": {
        first_name: "Legal First Name",
        middle_name: "Middle Name",
        last_name: "Last Name",
        suffix: "Suffix",
        suffix_eg: "Suffix (e.g. Jr., II, etc.)",
        date_of_birth: "Date of Birth",
        email_address: "Email Address",
        phone_number: "Phone Number",
        phone_type: "Phone Type",
        opt_in_text: "I opt in to receive texts.",
        required: "*Denotes required field",
        mobile_phone: "Mobile Phone",
        home_phone: "Home Phone",
        address_1: "Residence Address (legal voting residence) in WI",
        address_1_short: "Residence Address",
        address_2: "Address 2",
        mailing_address_1: "Address",
        mailing_address_2: "Mailing Address 2",
        foreign_mailing_address_1: "Address 1",
        pobox_mailing_address_1: "P.O. Box Number",
        new_address_1: "New Residence Address in WI",
        apt_type: "Apt/Unit Type",
        apt_number: "APT/Unit Number",
        city: "City",
        zipcode: "ZIP Code",
        wi_license_id: "WI Driver License or WI DOT Issued ID#",
        wi_license_id_caption: "Required if not expired or cancelled",
        id_expiration_date: "WI Driver License Expiration Date",
        last_four_ssn: "Last Four SSN",
        mailing_region: "State/Province/Region",
        mailing_zipcode: "Postal Code",
        mailing_country: "Mailing Country",
        us_mailing_address: "U.S. Mailing Address",
        foreign_mailing_address: "Foreign Mailing Address",
        po_box: "P.O. Box",
        assistant_address_1: "Assistant Address",
        state: "State",
        country: "Country",
        i_have_mailing_address: "I have a different mailing address.",
        po_box_number: "P.O. Box Number",
        mailing_address: "Mailing Address",
        last_four_ssn_long: "Last four digits of your SSN",

        previous_address: "Previous Address",
        previous_type: "Previous Apt/Unit Type",
        previous_number: "Previous APT/Unit Number",
        previous_city: "Previous City",
        previous_state: "Previous State",
        previous_zipcode: "Previous ZIP Code",

        select_option: "Select an item",
    },
    "es-ES": {
        first_name: "Primer nombre legal",
        middle_name: "Segundo nombre",
        last_name: "Apellido",
        suffix: "Sufijo",
        suffix_eg: "Sufijo (p. Ej., Jr., II, etc.)",
        date_of_birth: "Fecha de nacimiento",
        email_address: "Dirección de correo electrónico",
        phone_number: "Número de teléfono",
        phone_type: "Tipo de teléfono",
        opt_in_text: "Acepto recibir mensajes de texto. ",
        required: "*Indica un campo obligatorio",
        mobile_phone: "Teléfono celular",
        home_phone: "Teléfono de casa",
        address_1: "Dirección de residencia (residencia de votante legal) en WI",
        address_1_short: "Dirección de residencia",
        address_2: "Dirección 2",
        mailing_address_1: "Dirección postal",
        mailing_address_2: "Dirección postal 2",
        foreign_mailing_address_1: "Dirección 1",
        pobox_mailing_address_1: "Número de apartado postal (P.O. Box)",
        new_address_1: "Nueva dirección de residencia en WI",
        apt_type: "Tipo de unidad/apto",
        apt_number: "Número de unidad/apto",
        city: "Ciudad",
        zipcode: "Código postal",
        wi_license_id: "Licencia de conducir o ID emitida por el DOT de WI",
        wi_license_id_caption: "Obligatorio si no está vencida o cancelada",
        id_expiration_date: "Fecha de vencimiento de la licencia de conducir de WI",
        last_four_ssn: "Últimos cuatro dígitos de su SSN",
        mailing_region: "Estado/Provincia/Región",
        mailing_zipcode: "Código postal",
        mailing_country: "País",
        us_mailing_address: "Dirección postal en EE. UU.",
        foreign_mailing_address: "Dirección postal extranjera",
        po_box: "Número de apartado postal ",
        assistant_address_1: "Dirección del asistente",
        state: "Estado",
        country: "País",
        i_have_mailing_address: "Tengo una dirección postal diferente.",
        po_box_number: "Número de apartado postal (P.O. Box)",
        mailing_address: "Dirección postal",
        last_four_ssn_long: "Últimos cuatro dígitos de su SSN",


        previous_address: "Dirección anterior",
        previous_type: "Tipo de unidad/apto. anterior",
        previous_number: "Número de unidad/apto. anterior",
        previous_city: "Ciudad anterior",
        previous_state: "Estado anterior",
        previous_zipcode: "Código postal anterior",

        select_option: "Elija un ítem",
    },
};

export const formErrors = {
    "en-US": {
        required_first_name: "First name is required",
        valid_first_name: "First name is required",
        required_last_name: "Last name is required",
        valid_last_name: "Last name is required",
        valid_middle_name: "Valid Middle Name is required",
        required_date_of_birth: "Date of Birth is required",
        valid_date_of_birth: "Valid Date of Birth is required",
        required_phone_number: "Phone number is required",
        valid_phone_number: "Valid Phone Number is required",
        required_email: "Email address is required",
        valid_email: "Valid Email is required",
        phone_number_or_email: "Valid Email or Phone Number is required",
        valid_zipcode: "Valid zipcode is required",
        valid_postal_code: "Valid postal code is required",
        choose_option: "Please choose an option above",
        valid_id: "Valid ID Number is required",
        valid_id_expiration: "Valid Expiration Date is required",
        valid_ssn: "SSN Last Four is not valid",
        required_ssn: "SSN Last Four is required",
        address_required: "Address is required",
        valid_address_2: "Valid Address 2 is required",
        unit_number_required: "APT/Unit Number is required when type is set",
        city_required: "City is required",
        state_required: "State is required",
        state_is_wi: "State must be set to WI.",
        previous_state_is_wi: "Previous state must be set to WI. If your previous address is not a WI address, do not change address.",
        zipcode_required: "ZIP Code is required",
        country_required: "Country is required",
        is_required: " is required",
    },
    "es-ES": {
        required_first_name: "El nombre es obligatorio",
        valid_first_name: "El nombre es obligatorio",
        required_last_name: "El apellido es obligatorio",
        valid_last_name: "El apellido es obligatorio",
        valid_middle_name: "Es obligatorio un segundo nombre válido",
        required_date_of_birth: "La fecha de nacimiento es obligatoria",
        valid_date_of_birth: "Es obligatoria una fecha de nacimiento válida",
        required_phone_number: "Es obligatoria una fecha de nacimiento válida",
        valid_phone_number: "Es obligatorio un número de teléfono válido",
        required_email: "La dirección de correo electrónico es obligatoria",
        valid_email: "Es obligatorio un correo electrónico válido",
        phone_number_or_email: "Es obligatorio un correo electrónico o número de teléfono válido",
        valid_zipcode: "Es obligatorio un código postal válido",
        valid_postal_code: "Es obligatorio un código postal válido",
        choose_option: "Elija una  opción anterior",
        valid_id: "Es obligatorio un número de identificación (ID) válido",
        valid_id_expiration: "Es obligatoria una fecha de vencimiento válida",
        valid_ssn: "Los últimos cuatro dígitos de su SSN no son válidos",
        required_ssn: "Son obligatorios los últimos cuatro dígitos de su SSN",
        address_required: "La dirección es obligatoria",
        valid_address_2: "Es obligatoria La dirección 2 es obligatoria",
        unit_number_required: "Número de unidad/apto es obligatorio cuando se establece el tipo",
        city_required: "La ciudad es obligatoria",
        state_required: "El estado es obligatorio",
        state_is_wi: "El estado debe estar configurado para Wisconsin.",
        previous_state_is_wi: "El estado anterior debe estar configurado para Wisconsin. Si su dirección anterior no es una dirección de Wisconsin, no cambie de dirección.",
        zipcode_required: "El código postal es obligatorio",
        country_required: "País es obligatorio",
        is_required: " es obligatorio",
    },
};

export const buttons = {
    "en-US": {
        knowledge_base: "Knowledge Base",
        privacy_policy: "Privacy Policy",
        terms_and_conditions: "Terms and Conditions",
        got_to_go: "Got to Go",
        end_shift: "End Shift",
        upload_the_data: "Upload the Data",
        upload_data: "Upload Data",
        cancel_upload: "Cancel Upload",
        start_new_session: "Start New Session?",
        check_registration: "Check Registration",
        i_am_ineligible_to_vote: "I am ineligible to vote",
        next_step: "Next Step",
        register_online: "Register Online",
        i_am_registered: "I am registered!",
        register_to_vote: "Register To Vote",
        next_question: "Next Question",
        submit: "Submit",
        submit_registration: "Submit Registration",
        dont_followup: "Please don't follow up with me",
        need_id_help: "I need help getting an ID",
        cancel: "Cancel",
        start_new_registration: "Start New Registration",
        lets_stay_in_touch: "Let's Stay in Touch",
        retake_image: "Retake Image",
        finish_registration: "Finish Registration",
        no_proof: "I Don't Have a Proof of Residence on me",
        take_photo_id: "Take Photo of ID",
        other_forms_por: "Other Forms of POR",
        register_without_por: "Register Without POR",
        complete_later_online: "Complete Later Online",
        take_photo_por: "Take Photo of POR",
        get_started: "Get Started",
        couldnt_complete_online: "Couldn't Complete Online",
        complete_registration: "Complete Registration",
        update_information: "Update Information",
        accept_signature: "Accept Signature",
        clear_signature: "Clear Signature",
        edit_signature: "Edit Signature",
        add_signature: "Add Signature",
        accept_changes: "Accept Changes",
        capture_por: "Take Photo of POR",

        settings: "Settings",
        shift_time: "Shift Time",
        registrations: "Registrations",
        waiting_to_upload: "Waiting to Upload",
        ending_shift: "Ending Current Shift",
        logging_out: "Currently logging out and ending your shift. Please wait.",
        ready_to_upload: "This device has registrations ready to upload.",
        powered_by: "Powered by SeeBotRun",
        partner_logo: "Partner Logo",
        close: "Close",
    },
    "es-ES": {
        knowledge_base: "Base de información",
        privacy_policy: "Política de privacidad",
        terms_conditions: "Términos y condiciones",
        got_to_go: "Debe irse",
        end_shift: "Final del turno",
        upload_the_data: "Cargar los datos",
        upload_data: "Cargar datos",
        cancel_upload: "Cancelar carga",
        start_new_session: "¿Desea iniciar una nueva sesión?",
        check_registration: "Verificar registron",
        i_am_ineligible_to_vote: "No soy elegible para votar",
        next_step: "Siguiente paso",
        register_online: "Registrarse en línea",
        i_am_registered: "¡Estoy registrado!",
        register_to_vote: "Registrar para votar",
        next_question: "Siguiente pregunta",
        submit: "Enviar",
        submit_registration: "Enviar el registro",
        dont_followup: "No deseo que me vuelvan a contactar",
        need_id_help: "Necesito ayuda para obtener una ID",
        cancel: "Cancelar",
        start_new_registration: "Iniciar un nuevo registro",
        lets_stay_in_touch: "Mantengámonos en contacto",
        retake_image: "Volver a tomar la imagen",
        finish_registration: "Finalizar el registro",
        no_proof: "No tengo un POR conmigo ahora",
        take_photo_id: "Tomar foto de ID",
        other_forms_por: "Otras formas de POR",
        register_without_por: "Registrarse sin comprobante de residencia (POR)",
        complete_later_online: "Completar en línea más tarde",
        take_photo_por: "Tomar foto de POR",
        get_started: "Empezar",
        couldnt_complete_online: "No se pudo completar en línea",
        complete_registration: "Registro completado",
        update_information: "Actualizar información",
        accept_signature: "Aceptar la firma",
        clear_signature: "Borrar la firma",
        edit_signature: "Editar firma",
        add_signature: "Agregar firma",
        accept_changes: "Aceptar cambios",
        capture_por: "Tomar foto de ID",

        settings: "Configuraciones",
        shift_time: "Horario del turno",
        registrations: "Registros",
        waiting_to_upload: "Esperando para cargar",
        ending_shift: "Fin del turno actual",
        logging_out: "Registro actual de su salida o finalización de su turno. Espere por favor.",
        ready_to_upload: "Este dispositivo tiene registros listos para cargar.",
        powered_by: "Patrocinado por SeeBotRun",
        partner_logo: "Logo del socio",
        close: "Cerca",
    },
};
