import React from "react";

const ModalContainer = ({ children, isOpen }) => {
  return (
    <>
      {isOpen && (
        <div className="fixed w-full h-full top-0 left-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
          <div className="w-[80%] max-w-[960px] max-h-[80%] bg-white rounded-lg overflow-y-auto mx-auto my-20">
            <div className="w-full">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalContainer;
