
import React from 'react';
import PropTypes from 'prop-types';

const Paragraph = ({ children, centered}) => {
    const classes = `font-body text-[#13187C] text-md my-2 ${centered ? 'text-center' : 'text-left'}`;
    return  <p className={classes}>{children}</p>
};

Paragraph.propTypes = {
    children: PropTypes.node.isRequired
}

export default Paragraph;

