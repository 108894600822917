import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from "../components/layout/Wrapper";
import Paragraph from '../components/typography/Paragraph';
import Heading1 from '../components/typography/Heading1';
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";
import NewAddressForm from "../forms/NewAddressForm";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { useNavigate } from "react-router-dom";

export const content = {
    "en-US": {
        heading: "Have you updated your address with the Department of Motor Vehicles (DMV)?",
        paragraph: <>In order to change your address on your voter registration, we'll need to update your address with the <a href="https://trust.dot.state.wi.us/dmvac/dmvacservlet?whoami=search" target="_blank">DMV</a>.</>,
    },
    "es-ES": {
        heading: "¿Ha actualizado su dirección en el Departamento de Vehículos Motorizados (DMV en inglés)?",
        paragraph: <>Para cambiar su dirección en su registro de votante, debe actualizar su dirección en el <a href="https://trust.dot.state.wi.us/dmvac/dmvacservlet?whoami=search" target="_blank">DMV</a>.</>,
    }
};

function UpdateAddressFormPage({ navigation }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);

    const nextStep = (data) => {
        dispatch(updateRegistrant(data));
        navigate('/dob-consent');
    };

    React.useEffect(() => {
        dispatch(changeStep([2, 6]));
    }, []);

    return (
        <Wrapper>
            <ContentLeft>
                <Heading1 mb={4}>{content[currentLanguage].heading}</Heading1>
                <Paragraph mb={4}>{content[currentLanguage].paragraph}</Paragraph>
            </ContentLeft>
            <ContentRight>
                <NewAddressForm onSubmit={nextStep} />
            </ContentRight>
        </Wrapper>
    );
}

export default UpdateAddressFormPage;
