import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    check_first_name: '',
    check_last_name: '',
    check_date_of_birth_month: '',
    check_date_of_birth_day: '',
    check_date_of_birth_year: '',
    email_address: '',
    email_address_optin: false,
    phone_number_area: '',
    phone_number: '',
    phone_type: 'cell', //  cell, home
    phone_number_optin: false,
    registered: null,
    check_is_citizen: false,
    check_is_valid_age: false,
    check_is_legal: false,
    check_is_address_valid: false,
    information_correct: null, // ['correct', 'update_name', 'update_address', 'update_name_address']
    identity_type: 'none', // [ 'none' , 'has_id' , 'no_id' , 'has_ssn', 'no_id_ssn']
    id_assistance: false,
    first_name: '',
    last_name: '',
    middle_name: '',
    suffix: '',
    date_of_birth_month: '',
    date_of_birth_day: '',
    date_of_birth_year: '',
    is_dob_consented: false,
    consented_dob: '',
    wi_license_id: '',
    wi_license_expiration_month: '',
    wi_license_expiration_day: '',
    wi_license_expiration_year: '',
    address_1: '',
    address_2_type: '',
    address_2_number: '',
    city: '',
    state: '',
    zipcode: '',
    has_mailing_address: false,
    mailing_address_type: 'us', // 'us', 'foreign', 'po'
    mailing_address_1: '',
    mailing_address_2_type: '',
    mailing_address_2_number: '',
    mailing_city: '',
    mailing_state: '',
    mailing_region: '',
    mailing_zipcode: '',
    mailing_country: '',
    is_military: false,
    is_overseas: false,
    is_interested_poll_worker: false,
    accommodations: '',
    last_four_ssn: '',
    ssa_name_updated: null, // 'updated', 'not_updated', 'unsure'
    has_new_name: false,
    new_last_name: '',
    new_first_name: '',
    new_middle_name: '',
    new_suffix: '',
    dmv_address_updated: null, // 'updated', 'not_updated', 'unsure'
    has_new_address: false,
    new_address_1: '',
    new_address_2_type: '',
    new_address_2_number: '',
    new_city: '',
    new_state: '',
    new_zipcode: '',
    has_signature: false,
    signature_data: null,
    por_type_selected: null,
    has_proof_of_residence: false,
    has_proof_of_residence_2: false,
    proof_of_residence: null,
    has_assistant: false,
    assistant_address_1: '',
    assistant_address_2_type: '',
    assistant_address_2_number: '',
    assistant_city: '',
    assistant_state: '',
    assistant_zipcode: '',
    assistant_signature_data: null,
};

// Dummy data
// const initialState = {
//     check_first_name: 'Jane',
//     check_last_name: 'Goodall',
//     check_date_of_birth_month: '09',
//     check_date_of_birth_day: '14',
//     check_date_of_birth_year: '2001',
//     email_address: 'janedoe@email.com',
//     phone_number_area: '666',
//     phone_number: '6666666',
//     phone_type: 'cell', //  cell, home
//     registered: false,
//     check_is_citizen: false,
//     check_is_valid_age: false,
//     check_is_legal: false,
//     check_is_address_valid: false,
//     information_correct: null, // ['correct', 'update_name', 'update_address', 'update_name_address']
//     identity_type: 'has_id', // [ 'none' , 'has_id' , 'no_id' , 'has_ssn', 'no_id_ssn']
//     first_name: 'Johnny',
//     last_name: 'Rocket',
//     middle_name: 'Robert',
//     suffix: 'JR',
//     date_of_birth_month: '10',
//     date_of_birth_day: '08',
//     date_of_birth_year: '1999',
//     wi_license_id: 'S546-5465-4456-69',
//     wi_license_expiration_month: '09',
//     wi_license_expiration_day: '29',
//     wi_license_expiration_year: '1990',
//     address_1: '6969 Somewhere Pl.',
//     address_2_type: 'APT',
//     address_2_number: '69',
//     city: 'Hell',
//     state: 'WI',
//     zipcode: '42059',
//     has_mailing_address: true,
//     mailing_address_type: 'foreign', // 'us', 'foreign', 'po'
//     mailing_address_1: 'lakdjsflkasdf',
//     mailing_address_2_type: 'APT',
//     mailing_address_2_number: '123123',
//     mailing_city: 'lkadjfasd',
//     mailing_state: 'CA',
//     mailing_region: 'asdfasdfafasdf',
//     mailing_zipcode: '985986',
//     mailing_country: 'laksjdflakjf',
//     is_military: false,
//     is_overseas: false,
//     is_interested_poll_worker: true,
//     accommodations: 'Lorem ipsum dolor sit amet.',
//     last_four_ssn: '1234',
//     ssa_name_updated: null, // 'updated', 'not_updated', 'unsure'
//     has_new_name: true,
//     new_last_name: 'Newlast',
//     new_first_name: 'Newfirst',
//     new_middle_name: 'Newmiddle',
//     new_suffix: 'SR',
//     dmv_address_updated: null, // 'updated', 'not_updated', 'unsure'
//     has_new_address: true,
//     new_address_1: 'new_address_1',
//     new_address_2_type: 'APT',
//     new_address_2_number: '2',
//     new_city: 'new_city',
//     new_zipcode: '12345',
//     proof_of_residence: null,
//     has_assistant: false,
//     assistant_address_1: '',
//     assistant_address_2_type: '',
//     assistant_address_2_number: '',
//     assistant_city: '',
//     assistant_state: '',
//     assistant_zipcode: '',
// };

export const registrantSlice = createSlice({
    name: 'registrant',
    initialState: initialState,
    reducers: {
        updateRegistrant: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
        clearCurrentRegistrant: () =>  initialState,
    },
});

export const {
    updateRegistrant,
    clearCurrentRegistrant,
} = registrantSlice.actions;

export default registrantSlice.reducer;
