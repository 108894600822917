import React, { useEffect } from "react";
import Wrapper from "../components/layout/Wrapper";
import Heading1 from '../components/typography/Heading1';
import Paragraph from '../components/typography/Paragraph';
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../components/buttons/PrimaryButton";
import FormWrapper from '../components/layout/FormWrapper'
import ReturnUserForm from '../forms/ReturnUserForm';
import ContentRight from "../components/layout/ContentRight";
import ContentLeft from "../components/layout/ContentLeft";
import { updateRegistrant, clearCurrentRegistrant } from '../store/registrant/registrantSlice'
import { useDispatch, useSelector } from "react-redux";
import { buttons } from '../content';

export const content = {
  "en-US": {
      default_heading: "Welcome Back!",
      default_paragraph: "Please confirm your information so we can jump back into your registration.",
      notfound_heading: "Something Isn't Right",
      notfound_paragraph: "We're not able to find your previously started registration.",
      found_heading: "Hi ",
      found_paragraph: "It looks like you still need to provide us with some information.",
  },
  "es-ES": {
    default_heading: "¡Bienvenido de nuevo!",
    default_paragraph: "Confirme su información para que podamos regresar a su registro.",
    notfound_heading: "Algo no está bien",
    notfound_paragraph: "No podemos encontrar el registro que comenzó previamente.",
    found_heading: "Hola ",
    found_paragraph: "Parece que todavía debe brindarnos información.",
  }
};

const ReturnUserPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const registrant = useSelector((state) => state.registrant);
  const partnerInfo = useSelector((state) => state.partner);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  const [returnUserFound, setReturnUserFound] = React.useState(null);
  const [firstName, setFirstName] = React.useState('');
  
  const handleLookup = (response) => {
    if (response?.registrationStatus === true) {
      setReturnUserFound(true);
      setFirstName(response?.registrantInfo?.check_first_name || '');
      dispatch(updateRegistrant(response.registrantInfo));
    } else if (response?.registrationStatus === false) {
      setReturnUserFound(false);
    }
  };

  const startNewRegistration = () => {
    dispatch(clearCurrentRegistrant());
    navigate('/');
  };

  const finsihRegistration = () => {
    // console.log(partnerInfo);
    // console.log(registrant);
    if (partnerInfo.can_register) {
      if (registrant.check_is_address_valid && registrant.check_is_citizen && registrant.check_is_legal && registrant.check_is_valid_age) {
        if (registrant.has_signature) {
          if (partnerInfo.can_register_offline && !partnerInfo.offline_disabled) {
            navigate('/proof-of-residence-intro');
          } else {
            alert(partnerInfo.no_register_offline_message);
          }
        } else if (registrant.identity_type == 'has_id') {
          if (partnerInfo.can_register_online) {
            navigate('/register-online');
          } else if (partnerInfo.can_register_offline && !partnerInfo.offline_disabled) {
            navigate('/id-information');
          } else {
            alert(partnerInfo.no_register_message);
          }
        } else if (registrant.identity_type == 'no_id') {
          if (partnerInfo.can_register_online) {
            navigate('/register-online');
          } else if (partnerInfo.can_register_offline && !partnerInfo.offline_disabled) {
            navigate('/id-information');
          } else {
            alert(partnerInfo.no_register_message);
          }
        } else if (registrant.identity_type == 'has_ssn') {
          if (partnerInfo.can_register_offline && !partnerInfo.offline_disabled) {
            navigate('/id-information');
          } else {
            alert(partnerInfo.no_register_message);
          }
        } else {
          navigate('/dob-consent');
        }
      } else if (registrant.registered) {
        if (registrant.has_new_address) {
          if (registrant.dmv_address_updated === 'updated') {
            navigate('/dob-consent');
          } else if (registrant.has_new_name && registrant.ssa_name_updated === 'updated') {
            navigate('/update-address');
          } else if (registrant.has_new_name) {
            navigate('/update-name');
          } else {
            navigate('/update-address');
          }
        } else if (registrant.has_new_name) {
          if (registrant.ssa_name_updated === 'updated') {
            navigate('/dob-consent');
          } else {
            navigate('/update-name');
          }
        } else {
          navigate('/');
        }
      } else if (registrant.registered_status) {
        navigate('/dob-consent');
      } else {
        navigate('/');
      }
    } else {
      alert(partnerInfo.no_register_message);
    }
  };

  return (
    <Wrapper>
      <ContentLeft>
          {returnUserFound === null && (
            <>
              <Heading1 mb={4}>{content[currentLanguage].default_heading}</Heading1>
              <Paragraph mb={4}>{content[currentLanguage].default_paragraph}</Paragraph>
            </>
          )}
          {returnUserFound === false && (
            <>
              <Heading1 mb={4}>{content[currentLanguage].notfound_heading}</Heading1>
              <Paragraph mb={4}>{content[currentLanguage].notfound_paragraph}</Paragraph>
            </>
          )}
          {returnUserFound === true && (
            <>
              <Heading1 mb={4}>{content[currentLanguage].found_heading}{firstName}</Heading1>
              <Paragraph mb={4}>{content[currentLanguage].found_paragraph}</Paragraph>
            </>
          )}
      </ContentLeft>

      <ContentRight>
        {returnUserFound === null && (
          <FormWrapper my={4}>
            <ReturnUserForm onSubmit={handleLookup} />
          </FormWrapper>
        )}
        {returnUserFound === false && (
          <div className="relative">
            <div className="p-8">
              <div className="px-2">
                  <div className="flex flex-col-reverse md:flex-row mt-8 items-center justify-end">
                      <div className="ml-4">
                        <PrimaryButton onClick={startNewRegistration} title={buttons[currentLanguage].start_new_registration} />
                      </div>
                  </div>
              </div>
            </div>
          </div>
        )}
        {returnUserFound === true && (
          <div className="relative">
            <div className="p-8">
              <div className="px-2">
                  <div className="flex flex-col-reverse md:flex-row mt-8 items-center justify-end">
                      <div className="ml-4">
                        <PrimaryButton onClick={finsihRegistration} title={buttons[currentLanguage].finish_registration} />
                      </div>
                  </div>
              </div>
            </div>
          </div>
        )}
      </ContentRight>
    </Wrapper>
  );
}

export default ReturnUserPage;