import * as React from "react";
import Heading1 from "../components/typography/Heading1";
import Heading2 from "../components/typography/Heading2";
import Paragraph from "../components/typography/Paragraph";
import Webcam from "react-webcam";
import FormWrapper from "../components/layout/FormWrapper";
import PrimaryButton from "../components/buttons/PrimaryButton";
import TextButton from "../components/buttons/TextButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  updateRegistrant,
  clearCurrentRegistrant,
} from "../store/registrant/registrantSlice";
import {
  generateRegistrationID,
  createFormPayload,
  createImagePayload,
  sendSurveyData,
  getPublicKey,
} from "../services/api/voter";
import Bugsnag from "@bugsnag/js";
import ContentColumn from "../components/layout/ContentColumn";
import { buttons } from '../content';
import ModalContainer from "../components/layout/ModalContainer";

export const content = {
  "en-US": {
    heading: "We'll also need a photo of your ID as proof of residence.",
    paragraph: "There are a number of documents that you can use as proof of residence, including your valid and unexpired WI ID.",
    no_por_heading: "If you don't have a form of Proof of Residence, you can still register.",
    heading_1: "Wisconsin Driver License/ID Card",
    heading_2: "Employee ID Card",
    heading_3: "Property Tax Bill/Receipt",
    heading_4: "Residential Lease",
    heading_5: "College ID and Receipt",
    heading_6: "Utility Bill",
    heading_7: "Paycheck/Pay Stub/Check",
    heading_8: "Homeless/Care Facility Proof",
    heading_9: "Unknown",
    heading_10: "Other Forms of POR",
    paragraph_2: "A WI Driver License/ID Card, if not expired or canceled; may be used even if driving privileges have been revoked",
    paragraph_3: "Any other official identification card or license issued by a WI governmental body or unit",
    paragraph_4: "An employee ID card with a photograph, but not a business card",
    paragraph_5: "A real property tax bill or receipt for the current year or the year preceding the date of the election",
    paragraph_6: "A residential lease (does not count as proof of residence if elector submits form by mail)",
    paragraph_7: "A picture ID from a university, college or technical college coupled with a fee receipt or an on-campus housing listing provided by the university, college or technical college",
    paragraph_8: "A utility bill for the period commencing not earlier than 90 days before the day registration is made",
    paragraph_9: "(Homeless voters only) A letter from an organization that provides services to the homeless that identifies the voter and describes the location designated as the person's residence for voting purposes",
    paragraph_10: "A contract/intake document prepared by a residential care facility indicating that the occupant resides in the facility",
    paragraph_11: "A paycheck or pay stub",
    paragraph_12: "A check or other document issued by a unit of government",
  },
  "es-ES": {
    heading: "También necesitaremos una foto de su ID como comprobante de residencia.",
    paragraph: "Hay varios documentos que puede usar como comprobante de residencia, incluida su ID de WI válida y vigente.",
    no_por_heading: "Si no tiene ningún tipo de Comprobante de residencia, igual puede registrarse.",
    heading_1: "Licencia de conducir/tarjeta de identificación de Wisconsin",
    heading_2: "Tarjeta de ID del empleado",
    heading_3: "Factura/recibo de impuestos a la propiedad",
    heading_4: "Un alquiler residencial",
    heading_5: "Identificación universitaria y recibo",
    heading_6: "Factura de servicios públicos",
    heading_7: "Salario/recibo de sueldo/cheque",
    heading_8: "Comprobante de indigente/centro de asistencia",
    heading_9: "Desconocido",
    heading_10: "Otras formas de POR",
    paragraph_2: "Se puede usar una Licencia de conducir/Tarjeta de identificación de WI, si no está vencida o cancelada, incluso si se han revocado los privilegios de conducción.",
    paragraph_3: "Cualquier otra tarjeta o licencia de identificación oficial emitida por un organismo o unidad gubernamental de WI.",
    paragraph_4: "Una tarjeta de identificación de empleado con una fotografía, pero no una tarjeta comercial.",
    paragraph_5: "Una factura de impuesto inmobiliario o recibo perteneciente al año actual o anterior a la fecha de la elección.",
    paragraph_6: "Un alquiler residencial (no cuenta como prueba de residencia si el votante envía el formulario por correo).",
    paragraph_7: "Una ID con fotografía de una universidad, institución de educación superior o institución técnica junto con un recibo de aranceles o una lista de alojamiento en el campus que proporcione la universidad, institución de educación superior o institución técnica.",
    paragraph_8: "Una factura de servicios públicos correspondiente al período que comienza al menos 90 días antes del día del registro.",
    paragraph_9: "(Votantes sin hogar solamente) Una carta de una organización que presta servicios a las personas sin hogar que identifique al votante y describa la ubicación designada como la residencia de la persona para los fines de votación.",
    paragraph_10: "Un contrato/documento de registro preparado por una institución de cuidados residencial que indica que el ocupante reside en la institución.",
    paragraph_11: "Un comprobante de salario o pago.",
    paragraph_12: "Un cheque u otro documento emitido por una unidad del gobierno.",
  }
};

const PORIntroPage = () => {
  const registrant = useSelector((state) => state.registrant);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  const webcamRef = React.useRef(null);
  const [currentPOR, setCurrentPOR] = React.useState('wi_license');
  const [showPORModal, setShowPORModal] = React.useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const nextStep = async () => {
    dispatch(
      updateRegistrant({
        has_proof_of_residence: true,
      })
    );
  };

  const getCurrentPORHeading = () => {
    if (currentPOR == 'wi_license') {
      return content[currentLanguage].heading_1;
    } else if (currentPOR == 'employee_id') {
      return content[currentLanguage].heading_2;
    } else if (currentPOR == 'property_tax') {
      return content[currentLanguage].heading_3;
    } else if (currentPOR == 'residential_lease') {
      return content[currentLanguage].heading_4;
    } else if (currentPOR == 'college_id') {
      return content[currentLanguage].heading_5;
    } else if (currentPOR == 'utility_bill') {
      return content[currentLanguage].heading_6;
    } else if (currentPOR == 'paycheck') {
      return content[currentLanguage].heading_7;
    } else if (currentPOR == 'homeless') {
      return content[currentLanguage].heading_8;
    } else {
      return content[currentLanguage].heading_9;
    }
  };
  const getCurrentPORBody = () => {
    if (currentPOR == 'wi_license') {
      return <>
        <Paragraph my={1} mx={8} centered>
          {content[currentLanguage].paragraph_2}
        </Paragraph>
        <Paragraph my={1} mx={8} centered>
          {content[currentLanguage].paragraph_3}
        </Paragraph>
      </>;
    } else if (currentPOR == 'employee_id') {
      return <>
        <Paragraph my={1} mx={8} centered>
          {content[currentLanguage].paragraph_4}
        </Paragraph>
      </>;
    } else if (currentPOR == 'property_tax') {
      return <>
        <Paragraph my={1} mx={8} centered>
          {content[currentLanguage].paragraph_5}
        </Paragraph>
      </>;
    } else if (currentPOR == 'residential_lease') {
      return <>
        <Paragraph my={1} mx={8} centered>
          {content[currentLanguage].paragraph_6}
        </Paragraph>
      </>;
    } else if (currentPOR == 'college_id') {
      return <>
        <Paragraph my={1} mx={8} centered>
          {content[currentLanguage].paragraph_7}
        </Paragraph>
      </>;
    } else if (currentPOR == 'utility_bill') {
      return <>
        <Paragraph my={1} mx={8} centered>
          {content[currentLanguage].paragraph_8}
        </Paragraph>
      </>;
    } else if (currentPOR == 'paycheck') {
      return <>
        <Paragraph my={1} mx={8} centered>
          {content[currentLanguage].paragraph_11}
        </Paragraph>
        <Paragraph my={1} mx={8} centered>
          {content[currentLanguage].paragraph_12}
        </Paragraph>
      </>;
    } else if (currentPOR == 'homeless') {
      return <>
        <Paragraph my={1} mx={8} centered>
          {content[currentLanguage].paragraph_9}
        </Paragraph>
        <Paragraph my={1} mx={8} centered>
          {content[currentLanguage].paragraph_10}
        </Paragraph>
      </>;
    } else {
      return <>
        <Paragraph>Unknown</Paragraph>
      </>;
    }
  };

  return (
    <>
      <ContentColumn>
        <ContentColumn.Header>
          <Heading1>{content[currentLanguage].heading}</Heading1>
          <Paragraph>{content[currentLanguage].paragraph}</Paragraph>
        </ContentColumn.Header>
        <ContentColumn.Content>
          <FormWrapper>
            <div className="px-8">
              <button type="button"
                className="bg-[#B80F00] flex flex-nowrap rounded-full py-4 my-2 w-full justify-center"
                onClick={() => {
                  setCurrentPOR('wi_license');
                  setShowPORModal(true);
                }}
              >
                <div className="icon-container">
                  <svg className="icon-svg" viewBox="0 0 576 512"><path d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z" /></svg>
                  
                  {content[currentLanguage].heading_1}
                </div>
              </button>
              <button type="button"
                className="bg-[#B80F00] flex flex-nowrap rounded-full py-4 my-2 w-full justify-center"
                onClick={() => {
                  setCurrentPOR('employee_id');
                  setShowPORModal(true);
                }}
              >
                <div className="icon-container">
                  <svg className="icon-svg" viewBox="0 0 576 512"><path d="M256 0h64c17.7 0 32 14.3 32 32V96c0 17.7-14.3 32-32 32H256c-17.7 0-32-14.3-32-32V32c0-17.7 14.3-32 32-32zM64 64H192v48c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V64H512c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64zM176 437.3c0 5.9 4.8 10.7 10.7 10.7H389.3c5.9 0 10.7-4.8 10.7-10.7c0-29.5-23.9-53.3-53.3-53.3H229.3c-29.5 0-53.3 23.9-53.3 53.3zM288 352a64 64 0 1 0 0-128 64 64 0 1 0 0 128z" /></svg>
                  {content[currentLanguage].heading_2}
                </div>

              </button>
              <button type="button"
                className="bg-[#B80F00] flex flex-nowrap rounded-full py-4 my-2 w-full justify-center"
                onClick={() => {
                  setCurrentPOR('property_tax');
                  setShowPORModal(true);
                }}
              >
                <div className="icon-container">
                  <svg className="icon-svg" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM64 80c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16zm128 72c8.8 0 16 7.2 16 16v17.3c8.5 1.2 16.7 3.1 24.1 5.1c8.5 2.3 13.6 11 11.3 19.6s-11 13.6-19.6 11.3c-11.1-3-22-5.2-32.1-5.3c-8.4-.1-17.4 1.8-23.6 5.5c-5.7 3.4-8.1 7.3-8.1 12.8c0 3.7 1.3 6.5 7.3 10.1c6.9 4.1 16.6 7.1 29.2 10.9l.5 .1 0 0 0 0c11.3 3.4 25.3 7.6 36.3 14.6c12.1 7.6 22.4 19.7 22.7 38.2c.3 19.3-9.6 33.3-22.9 41.6c-7.7 4.8-16.4 7.6-25.1 9.1V440c0 8.8-7.2 16-16 16s-16-7.2-16-16V422.2c-11.2-2.1-21.7-5.7-30.9-8.9l0 0c-2.1-.7-4.2-1.4-6.2-2.1c-8.4-2.8-12.9-11.9-10.1-20.2s11.9-12.9 20.2-10.1c2.5 .8 4.8 1.6 7.1 2.4l0 0 0 0 0 0c13.6 4.6 24.6 8.4 36.3 8.7c9.1 .3 17.9-1.7 23.7-5.3c5.1-3.2 7.9-7.3 7.8-14c-.1-4.6-1.8-7.8-7.7-11.6c-6.8-4.3-16.5-7.4-29-11.2l-1.6-.5 0 0c-11-3.3-24.3-7.3-34.8-13.7c-12-7.2-22.6-18.9-22.7-37.3c-.1-19.4 10.8-32.8 23.8-40.5c7.5-4.4 15.8-7.2 24.1-8.7V232c0-8.8 7.2-16 16-16z" /></svg>
                 
                  {content[currentLanguage].heading_3}
                </div>

              </button>
              <button type="button"
                className="bg-[#B80F00] flex flex-nowrap rounded-full py-4 my-2 w-full justify-center"
                onClick={() => {
                  setCurrentPOR('college_id');
                  setShowPORModal(true);
                }}
              >
                <div className="icon-container">
                  <svg className="icon-svg" viewBox="0 0 512 512"><path d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" /></svg>
                  {content[currentLanguage].heading_5}
                </div>

              </button>
              <button type="button"
                className="bg-[#B80F00] flex flex-nowrap rounded-full py-4 my-2 w-full justify-center"
                onClick={() => {
                  setCurrentPOR('utility_bill');
                  setShowPORModal(true);
                }}
              >
                <div className="icon-container">
                <svg className="icon-svg" viewBox="0 0 384 512"><path d="M0 256L28.5 28c2-16 15.6-28 31.8-28H228.9c15 0 27.1 12.1 27.1 27.1c0 3.2-.6 6.5-1.7 9.5L208 160H347.3c20.2 0 36.7 16.4 36.7 36.7c0 7.4-2.2 14.6-6.4 20.7l-192.2 281c-5.9 8.6-15.6 13.7-25.9 13.7h-2.9c-15.7 0-28.5-12.8-28.5-28.5c0-2.3 .3-4.6 .9-6.9L176 288H32c-17.7 0-32-14.3-32-32z" /></svg>
                 
                  {content[currentLanguage].heading_6}
                </div>

              </button>

              <button type="button"
                className="bg-[#B80F00] flex flex-nowrap rounded-full py-4 my-2 w-full justify-center"
                onClick={() => {
                  setCurrentPOR('paycheck');
                  setShowPORModal(true);
                }}
              >
                <div className="icon-container">
                <svg className="icon-svg" viewBox="0 0 576 512"><path d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zm48 160H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zM96 336c0-8.8 7.2-16 16-16H464c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16zM376 160h80c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H376c-13.3 0-24-10.7-24-24V184c0-13.3 10.7-24 24-24z" /></svg>
                  
                  {content[currentLanguage].heading_7}
                </div>

              </button>
              <button type="button"
                className="bg-[#B80F00] flex flex-nowrap rounded-full py-4 my-2 w-full justify-center"
                onClick={() => {
                  setCurrentPOR('homeless');
                  setShowPORModal(true);
                }}
              >
                <div className="icon-container">
                <svg className="icon-svg" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm0 32v64H288V256H96zM240 416h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16s7.2-16 16-16z" /></svg>
                  
                  {content[currentLanguage].heading_8}
                </div>
              </button>
              <button type="button"
                className="bg-[#B80F00] flex flex-nowrap rounded-full py-4 my-2 w-full justify-center"
                onClick={() => {
                  setCurrentPOR('other');
                  dispatch(updateRegistrant({
                    has_proof_of_residence: true,
                    por_type_selected: 'other',
                  }));
                  navigate("/proof-of-residence");
                }}
              >
                <div className="icon-container">
                <svg className="icon-svg" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM80 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H80c-8.8 0-16-7.2-16-16s7.2-16 16-16zm16 96H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V256c0-17.7 14.3-32 32-32zm0 32v64H288V256H96zM240 416h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H240c-8.8 0-16-7.2-16-16s7.2-16 16-16z" /></svg>
                  
                  {content[currentLanguage].heading_10}
                </div>
              </button>
            </div>
          </FormWrapper>
        </ContentColumn.Content>
      </ContentColumn>
      <ModalContainer
        isOpen={showPORModal}
        onClose={() => setShowPORModal(false)}
      >
        <FormWrapper>
          <div className="px-6 flex flex-col items-center">
            <Heading1 centered>{getCurrentPORHeading()}</Heading1>
            {getCurrentPORBody()}
            <div className="mt-4 flex flex-col">
              <PrimaryButton onClick={() => {
                dispatch(updateRegistrant({
                  has_proof_of_residence: true,
                  por_type_selected: currentPOR,
                }));
                setShowPORModal(false);
                navigate("/proof-of-residence");
              }} title={buttons[currentLanguage].take_photo_id} />
              <TextButton onClick={() => {
                setShowPORModal(false);
              }}>{buttons[currentLanguage].close}</TextButton>
            </div>
          </div>
        </FormWrapper>
      </ModalContainer>
    </>
  );
};

export default PORIntroPage;
