import * as React from 'react';
import * as RadioGroup from '@radix-ui/react-radio-group';

const RadioButtonList = ({ items, ...props }) => {
    const radioItems = items.map(item => {
        return (
            <div key={item.value} className="flex items-center">
                <RadioGroup.Item
                    key={item.value}
                    id={item.id || item.value}
                    className="flex-shrink-0 bg-white w-[25px] h-[25px] border-[#13187C] border-2 rounded-full outline-none cursor-default"
                    value={item.value}
                >
                    <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[11px] after:h-[11px] after:rounded-[50%] after:bg-[#13187C]" />
                </RadioGroup.Item>
                <label className="text-[#13187C] cursor-pointer text-md leading-6 pl-[15px]" htmlFor={item.id || item.value}>
                    {item.label}
                </label>
            </div>
        )
    });
    return (
        <RadioGroup.Root
            onValueChange={props.onChange}
            className="flex flex-col gap-4 mb-4"
            value={props.value}
        >
            {radioItems}
        </RadioGroup.Root>
    )
};

export default RadioButtonList;
